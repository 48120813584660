import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import SuccessPage from './pages/SuccessPage';
import SettingsPage from './pages/settingsPage';
import Sidebar from './components/sidebar/Sidebar';
import { AuthProvider } from './components/AuthContext/AuthContext';
import Layout from './components/layout/layout';
// import { TokenProvider } from './components/TokenHandler/tokenHandler';



const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
 
  const isTokenExpired = (token: string | null) => {
    if (!token) return true; 
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    console.log('Current Time:', currentTime, 'Token Expiration Time:', payload.exp);

    return currentTime >= payload.exp; // Check if current time is greater than token expiration time
  };

  const checkToken = () => {
    const token = sessionStorage.getItem('authToken');
    console.log('Checking token:', token);
    
    if (isTokenExpired(token)) {
      console.log('Token expired, redirecting to login...');
      sessionStorage.removeItem('authToken'); // Clear the expired token
      navigate(`/`); // Redirect to the login page
    }
  };

  // useEffect(() => {
  //   checkToken();
  // }, [location.pathname]); // Run on every route change

  const getRedirectPath = () => {
    if (location.pathname.includes('/nbt_saathi_admin')) {
      sessionStorage.setItem("nbt_user", "nbt_saathi_admin");
      return '/nbt_saathi_admin';
    }
    if (location.pathname.includes('/nbt_admin')) {
      sessionStorage.setItem("nbt_user", "nbt_admin");
      return '/nbt_admin';
    }
    if(location.pathname.includes('/protean_admin')){
      sessionStorage.setItem("nbt_user", "protean_admin");
      return '/protean_admin';
    }
    return '/'; // Default route
  };

  return (
    <Routes>
      <Route path="/" element={<Navigate to={getRedirectPath()} />} />
      <Route path="/nbt_admin" element={<LoginForm />} />
      <Route path="/nbt_saathi_admin" element={<LoginForm />} />
      <Route path="/protean_admin" element={<LoginForm />} />
      <Route path={`/:nbt_user/success`} element={
        <>
          <Sidebar />
          <div style={{ marginLeft: '50px', padding: '20px' }}>
            
            <SuccessPage  />  
          </div>
        </>
      } />
      <Route path={`/:nbt_user/settings`} element={
        <>
          <Sidebar />
          <div style={{ marginLeft: '50px', padding: '20px' }}>
            <SettingsPage />
          </div>
        </>
      } />
            
    </Routes>
  );
};

const RootApp = () => (
    <App />
);

export default RootApp;
