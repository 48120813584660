import React, { useState, useEffect, ReactNode, useRef } from "react";
import "./DataPage.css";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import ArrowDropDownCircleRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";
import { error, log } from "console";
import { useParams } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axiosInstance from "../components/API/axiosInstance";
import CancelIcon from '@mui/icons-material/Cancel';
import { SelectChangeEvent } from "@mui/material/Select"; // Import SelectChangeEvent
import { Oval } from 'react-loader-spinner'
import axios from "axios";
import URLS from "../components/Urls/urls";
interface DataItem {
  id: number;
  first_name: string;
  last_name: string;
  contact_number: number;
  employment_type: string;
  income: number;
  state: string;
  pincode: string;
  transaction_id: string;
  created_on: string;
  status: string;
  agent_id: string;
  loan_type: string;
  date: string;
  bpp_name: string;
  settlement_amount: number;
  loan_amount: number;
  rate_of_interest: number;
  tenure: string;
  disbursement_amount: number;
  disbursement_date: string;
  clientCode:string;
  offer_type:string
}
interface TooltipProps {
  text: ReactNode;
  children: ReactNode;
}
interface Client {
  clientCode: string;
  clientName: string;
}

const SuccessPage: React.FC = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [page, setPage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DataItem | null>(null);
  const [selectedTab, setSelectedTab] = useState<"tab1" | "tab2">("tab1");
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [currentPageTab1, setCurrentPageTab1] = useState(0);
  const [currentPageTab2, setCurrentPageTab2] = useState(0);
  const [dataTab1, setDataTab1] = useState<DataItem[]>([]);
  const [dataTab2, setDataTab2] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchType, setSearchType] = useState("");
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputDate, setInputDate] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [total_pages, setTotalPages] = useState(0);
  const [size, setSize] = useState<number | "custom">(10);
  const [customSize, setCustomSize] = useState("");
  const [clients, setClients] = useState<Client[]>([]);
  const [clientId, setClientId] = useState<string>("");
  const [clientCode,setClientCode] =useState();
  const [clientName, setClientName] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null); // Reference to the input
  const navigate = useNavigate();

  //const itemsPerPage = 10; // Number of items per page
  const token = sessionStorage.getItem("authToken") || ""; // Replace with the actual token storage method
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  // Decode the base64-encoded payload
  const decodedData = JSON.parse(atob(base64));
  const userId = decodedData.given_name[0] + decodedData.family_name[0];
  const fullName = `Name:${decodedData.name} `;
  const companyName = `Company Name:${decodedData.azp}`;
  const nbt_user = sessionStorage.getItem("nbt_user");
  console.log("Initials", nbt_user);

  //console.log("In successPage. authtoken: "+token);
  const [params, setParams] = useState(
    () =>
      new URLSearchParams({
        // page: page ||'0',
        size: "10",
      })
  );

  useEffect(() => {
    const newParams = new URLSearchParams({
      // page: page ||'0',
      size: size === "custom" ? customSize : size.toString(),
      // dashboardType: selectedTab === 'tab1'?'lead':'disbursed',
    });

    if (searchQuery) {
      newParams.set("searchPhrase", searchQuery);
    } else {
      newParams.delete("searchPhrase"); // If searchQuery is empty, remove the searchPhrase parameter
    }
    if (fromDate) {
      newParams.set("fromDate", fromDate);
    } else {
      newParams.delete("fromDate"); // If searchQuery is empty, remove the searchPhrase parameter
    }
    if (toDate) {
      newParams.set("toDate", toDate);
    } else {
      newParams.delete("toDate"); // If searchQuery is empty, remove the searchPhrase parameter
    }
    if (filter) {
      newParams.set("searchType", filter);
    }
    if (nbt_user === 'protean_admin') {
      if (clientId) {
        newParams.set("clientId", clientId)
      }
    }

    setParams(newParams); // Update params state
  }, [inputDate, searchQuery, currentPageTab1, currentPageTab2, filter, size, clientId]);
console.log(token,"TOken for first Login");

  useEffect(() => {
    if (selectedTab === "tab1") {
      // Fetch data for tab1
      const fetchData = async () => {
        // setLoading(true);

        // try {
        //   const responseTab1 = await fetch(
        //     `${
        //       process.env.REACT_APP_API_BASE_URL
        //     }/admin/${nbt_user}/dashboard?dashboardType=lead&page=${currentPageTab1}&${params.toString()}`,
        //     {
        //       method: "GET",
        //       headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //         Authorization: `Bearer ${token}`, // Include the auth token in the header
        //         "ngrok-skip-browser-warning": "69420",
        //       },
        //     }
        //   );

        //   if (!responseTab1.ok) {
        //     throw new Error(`HTTP error! status: ${responseTab1.status}`);
        //   }

        //   const dataTab1 = await responseTab1.json();
        //   // console.log("Data Tab1", dataTab1);
        //   setDataTab1(dataTab1.content);
        // } catch (error) {
        //   console.error("Error fetching data:", error);
        // } finally {
        //   setLoading(false);
        // }
        try {
          const path = nbt_user === 'protean_admin' 
    ? `${URLS.get_lead_admin}${currentPageTab1}&${params.toString()}`
    : `${URLS.get_lead}${currentPageTab1}&${params.toString()}`;

          const responseTab1 = await axiosInstance.get(path,{
              headers: {
                Authorization: `Bearer ${token}`, // Include the auth token
              },
            }
          );

          setDataTab1(responseTab1?.data?.content);
          setTotalPages(responseTab1?.data?.total_pages);
          console.log("172", responseTab1.data.content);
          console.log("180", size, "Size" + customSize, "customSize");

        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else if (selectedTab === "tab2") {
      // Fetch data for tab2

      const fetchData = async () => {
        setLoading(true);
const path= nbt_user === 'protean_admin' 
? `${URLS.get_disbursed_admin}${currentPageTab2}&${params.toString()}`
: `${URLS.get_disbursed}${currentPageTab2}&${params.toString()}`;
        try {

          const responseTab2 = await axiosInstance.get(path,{
              headers: {
                Authorization: `Bearer ${token}`, // Include the auth token
              },
            }
          );
          setDataTab2(responseTab2?.data?.content);
          setTotalPages(responseTab2?.data?.total_pages);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedTab, token, params]);

  const handleTabChange = (tab: "tab1" | "tab2") => {
    setSelectedTab(tab);

    // Reset filters
    setFromDate("");
    setToDate("");
    setInputValue("");
    setSearchQuery("");
    setFilter("");
    setCurrentPageTab1(0); // Reset pagination for tab1
    setCurrentPageTab2(0); // Reset pagination for tab2
    setSize(10);
    setCustomSize('');
    setClientId('');
    setClientName('');
    setInputDate("");
  };

  // Function to filter data by date and search query
  const filteredData = (data: DataItem[]) => {
    console.log("data: ", data);
    console.log();

    return data.filter((item) => {
      // const itemDate = new Date(item.date);
      const n = `${item?.created_on[0]}-${item?.created_on[1]
        .toString()
        .padStart(2, "0")}-${item?.created_on[2].toString().padStart(2, "0")}`;
      console.log(n, "New Date");

      const itemDate = new Date(
        `${item?.created_on[0]}-${item?.created_on[1]
          .toString()
          .padStart(2, "0")}-${item?.created_on[2].toString().padStart(2, "0")}`
      );
      const startDate = fromDate ? new Date(fromDate) : new Date("1900-01-01");
      const endDate = toDate ? new Date(toDate) : new Date();
      const matchesSearchQuery =
        item &&
        ((item?.first_name &&
          item?.first_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.agent_id &&
            item?.agent_id.toString().includes(searchQuery.toLowerCase())) ||
          (item?.status &&
            item?.status.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item?.contact_number &&
            item?.contact_number
              .toString()
              .includes(searchQuery.toLowerCase())));

      return itemDate >= startDate && itemDate <= endDate && matchesSearchQuery;
    });
  };

  // Pagination logic
  const renderData = (data: DataItem[], currentPage: number) => {
    console.log(currentPage, "Inside rendder");

    const startIndex = 0; /*(currentPage - 1) * itemsPerPage;*/
    return data.slice(startIndex, startIndex + (typeof size === "number" ? size : 10));
  };
  // const getPaginationItems = (currentPage:number, total_pages:number) => {
  //   const paginationItems = [];

  //   // Show first three pages
  //   for (let i = 1; i <= Math.min(3, total_pages); i++) {
  //     paginationItems.push(i);
  //   }

  //   // Add ellipsis if needed
  //   if (total_pages > 3) {
  //     if (currentPage > 4) {
  //       paginationItems.push("...");
  //     }
  //     // Show current page and surrounding pages
  //     if (currentPage > 1) {
  //       paginationItems.push(currentPage);
  //     }
  //     if (currentPage < total_pages) {
  //       paginationItems.push(currentPage + 1);
  //     }
  //     // Show last page
  //     if (currentPage < total_pages - 1) {
  //       paginationItems.push("...");
  //       paginationItems.push(total_pages);
  //     }
  //   }

  //   return paginationItems;
  // };
  // const renderPagination = (currentPageTab1:number, total_pages:number) => {
  //   const paginationItems = getPaginationItems(currentPageTab1, total_pages);

  //   return (
  //     <div>
  //       {paginationItems.map((item, index) => {
  //         if (item === "...") {
  //           return <span key={index}>...</span>;
  //         }

  //         return (
  //           <button
  //             key={index}
  //             style={{
  //               color: currentPageTab1 === total_pages - 1 ? "#ccc" : "white",
  //               backgroundColor: currentPageTab1 === total_pages - 1 ? "#8A8A8A" : "#031A6E",
  //               marginRight: "10px",
  //               borderRadius: "5px",
  //               padding: "5px 10px",
  //               cursor: currentPageTab1 === total_pages - 1 ? "not-allowed" : "pointer",
  //             }}
  //             onClick={() => {
  //               setCurrentPageTab1(total_pages - 1);
  //             }}
  //             disabled={currentPageTab1 === total_pages - 1}
  //           >
  //             {item}
  //           </button>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const handlePageChange = (
    tab: "tab1" | "tab2",
    direction: "prev" | "next"
  ) => {
    console.log(currentPageTab1, "outer IF");

    if (tab === "tab1") {
      if (direction === "prev" && currentPageTab1 > 0) {
        console.log(currentPageTab1, "Cuureent PAge on NEXT prev");
        setCurrentPageTab1(currentPageTab1 - 1);
      } else if (
        direction ===
        "next" /* &&currentPageTab1 == Math.ceil(filteredData(dataTab1).length / itemsPerPage)*/
      ) {
        console.log(currentPageTab1, "Cuureent PAge on NEXT NEXT VALA ");
        setCurrentPageTab1(currentPageTab1 + 1);
      }
    } else {
      if (direction === "prev" && currentPageTab2 > 0) {
        setCurrentPageTab2(currentPageTab2 - 1);
      } else if (
        direction ===
        "next" /*&& currentPageTab2 < Math.ceil(filteredData(dataTab2).length / itemsPerPage)*/
      ) {
        setCurrentPageTab2(currentPageTab2 + 1);
      }
    }
  };


  const handleSearchChange = (e: { target: { value: any } }) => {
    setInputValue(e.target.value);
  };
  const handleSearchClick = () => {
    setSearchQuery(inputValue);
    setCurrentPageTab1(0);
    setCurrentPageTab2(0);
    // You can add additional logic here to perform the search


  };
  const handleSearchDate = () => {
    if (selectedTab === "tab1") {
      // Fetch data for tab1
      const searchData = async () => {
        const path = nbt_user === 'protean_admin' 
        ? `${URLS.get_lead_admin}${currentPageTab1}&${params.toString()}`
        : `${URLS.get_lead}${currentPageTab1}&${params.toString()}`;
    
        try {
          const responseTab1 = await axiosInstance.get(path,{
            headers: {
              Authorization: `Bearer ${token}`, // Include the auth token
            },
          }
        );

          setInputDate(responseTab1?.data?.content);
          setCurrentPageTab1(0);
          console.log("172", responseTab1.data.content);

        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      searchData();
    }
    else if (selectedTab === "tab2") {
      // Fetch data for tab2

      const searchData = async () => {
        //setLoading(true);

        const path = nbt_user === 'protean_admin' 
        ? `${URLS.get_lead_admin}${currentPageTab2}&${params.toString()}`
        : `${URLS.get_lead}${currentPageTab2}&${params.toString()}`;
    
        try {
          const responseTab2 = await axiosInstance.get(path,{
            headers: {
              Authorization: `Bearer ${token}`, // Include the auth token
            },
          }
        );

          setInputDate(responseTab2?.data?.content);
          setCurrentPageTab2(0);
          console.log("172", responseTab2.data.content);

        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      searchData();
    }


  }
  const handleSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSize = event.target.value;
    if (selectedSize === "custom") {
      //setSize(10); // Default size when "Custom" is selected
      setSize("custom");
      setCustomSize(""); // Reset custom size input
    } else {
      setSize(Number(selectedSize)); // Update size based on user input
      setCustomSize(""); // Reset custom size when selecting predefined options
    }

  };
  const handleCustomSizeChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = inputRef.current?.value || "";
      if (/^\d+$/.test(value) || value === "") { // Allow only numbers or empty string
        setCustomSize(value);


        if (value) {
          setSize(Number(value)); // Set custom size if valid
        }
        else {
          setSize("custom"); // If input is empty, set size back to custom
        }
      }
    }

  };
  // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === 'Enter') {
  //     handleCustomSizeChange(event)
  //   }
  // };

const handleAdminClick = () =>{
  if (selectedTab === "tab1") {
    // Fetch data for tab1
    const AdminData = async () => {
      try {
        const responseTab1 = await axiosInstance.get(
          `${URLS.get_client_admin}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the auth token
            },
          }

        );

        setInputDate(responseTab1?.data?.content);
        console.log("172", responseTab1.data.content);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    AdminData();
  }
  else if (selectedTab === "tab2") {
    // Fetch data for tab2

    const AdminData = async () => {
      //setLoading(true);

      try {
        const responseTab2 = await axiosInstance.get(
          `${URLS.get_client_admin}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the auth token
            },
          }
        );
        setInputDate(responseTab2?.data?.content);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    AdminData();
  }


}
  const handelSelectFilter = (e: { target: { value: any } }) => {
    const newOption = e.target.value;
    setFilter(newOption);
    setInputValue("");
    setSearchQuery("");
    console.log("Selected value", newOption);
  };


  // Function to open modal with selected item details
  const openModal = (item: DataItem) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const formatDateArray = (dateArray: number[]): string => {
    const [year, month, day, hour, minute, second, millisecond] = dateArray;
    const date = new Date(
      year,
      month - 1,
      day,
      hour,
      minute,
      second,
      millisecond
    ); // Month is zero-based
    const formattedDay = String(date.getDate()).padStart(2, "0");
    const formattedMonth = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const formattedYear = date.getFullYear();
    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  };

  // CSV download functionality
  const downloadCSV = () => {
    const dataToDownload =
      selectedTab === "tab1" ? (dataTab1) : (dataTab2);
    let csvContent;
    let headers;

    if (selectedTab === "tab1") {
      headers =
        "ID,First Name,Last Name,Contact No,Employment Type,Income,State,Pincode,Transaction ID,Created On,Status,Offer Type,Agent ID,Loan Type";
      csvContent =
        headers +
        "\n" +
        dataToDownload
          .map(
            (item) =>
              `${item?.id},${item?.first_name},${item?.last_name},${item?.contact_number
              },${item?.employment_type},${item?.income},${item?.state},${item?.pincode
              },${item?.transaction_id},${item?.created_on},${item?.status
              },${item?.offer_type},${item?.agent_id},${item?.loan_type}`
          )
          .join("\n");
    } else {
      headers =
        "ID,First Name,Last Name,Contact No,Employment Type,Income,State,Pincode,Transaction ID,Created On,Status,Offer Type,Agent ID,Loan Type,Settlement Amount,Lender Name,Disbursement Amount,Disbursement Date";
      csvContent =
        headers +
        "\n" +
        dataToDownload
          .map(
            (item) =>
              `${item?.id},${item?.first_name},${item?.last_name},${item?.contact_number
              },${item?.employment_type},${item?.income},${item?.state},${item?.pincode
              },${item?.transaction_id},${item?.created_on},${item?.status
              },${item?.offer_type},${item?.agent_id},${item?.loan_type},${item?.settlement_amount},${item?.bpp_name
              },${item?.disbursement_amount},${item?.disbursement_date}`
          )
          .join("\n");
    }

    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLogout = () => {
    // Remove the token from localStorage
    sessionStorage.removeItem("authToken");

    // Redirect to login page
    navigate(`/${nbt_user}`);
    handleClose();
  };
  
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_BACKEND_ENV}${URLS.get_fetch_clients}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Bearer token
            },
          }
        );// Update clients state with fetched data
        setClients(response.data); // Assuming response.data is an array of client objects
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };
  
    fetchClients();
  }, []); // Fetch clients once on component mount

  const handleClientChange = (event: SelectChangeEvent<string>) => {
    const selectedClientCode = event.target.value as string;
    setClientId(selectedClientCode);
    // Find the selected client and set the client name
    const selectedClient = clients.find(client => client.clientCode === selectedClientCode);
    setClientName(selectedClient ? selectedClient.clientName : "");
  };
  const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
    return (
      <div className="tooltip-container">
        {children}
        <span className="tooltip">{text}</span>
      </div>
    );
  };
  const adminDashboard = sessionStorage.getItem('nbt_user')
  console.log(adminDashboard, "userAdminashboard");

  return (
    <div>
      <>
        {/* Search, Date Filters, and CSV Download */}
        <div>
          <div className="header">
            <h1 style={{ color: "#324589", textAlign: "left" }}>
              Welcome User
            </h1>
            <button onClick={handleOpen} className="logout-button">
              <LogoutIcon />
            </button>
            <Tooltip
              text={
                <>
                  <span>{fullName}</span>
                  <br />
                  <span>{companyName}</span>{" "}
                  {/* Add your additional text here */}
                </>
              }
            >
              <p className="user-id">{userId}</p>
            </Tooltip>
            <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: "1000px", // Set your desired width
                  height: "200px", // Set your desired height
                },
              }}
            >
              <DialogTitle>
                <ErrorOutlineIcon style={{ color: "#ffa700" }} /> Logout
                Confirmation
              </DialogTitle>
              <DialogContent>
                <DialogContentText style={{ fontWeight: "bold" }}>
                  Are you sure you want to logout?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  style={{ color: "white", backgroundColor: "#031A6E" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleLogout}
                  style={{ color: "white", backgroundColor: "#031A6E" }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <hr style={{ color: "#ECF0E5", opacity: "1" }} />
          <h1
            style={{
              marginTop: "50px",
              fontSize: "15px",
              color: "#324589",
              display: "flex",
              gap: "7px",
            }}
          >
            <SpaceDashboardOutlinedIcon />
            Data Page
          </h1>
          <hr style={{ marginTop: "10px", color: "#ECF0E5", opacity: "1" }} />
          <div className="filters-container">
            <div className="search-container">
              {nbt_user === 'protean_admin' ? (
                <>
          <FormControl
        variant="outlined"
        size="small"
        style={{ marginRight: "10px", minWidth: "120px" }}
      >
        <InputLabel>Client Code</InputLabel>
        <Select
          value={clientId}
          onChange={handleClientChange} // Handle selection change
          label="Client Code"
          style={{ borderColor: "#324589" }}
        >
        {clients.map((client) => (
            <MenuItem key={client.clientCode} value={client.clientCode}>
              {client.clientCode.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        placeholder="Selected Client Name"
        value={clientName} // Display the selected client name here
        variant="outlined"
        size="small"
        style={{
          marginRight: "10px",
          borderRadius: "4px",
          borderColor: "#324589",
        }}
        disabled // Disable the input box since it's only for display
      />
                </>
              ) : (
                <>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ marginRight: "10px", minWidth: "120px" }}
                  >
                    <InputLabel>Filter</InputLabel>
                    <Select
                      value={filter}
                      label="Filter"
                      style={{ borderColor: "#324589" }}
                      onChange={handelSelectFilter}
                    >
                      <MenuItem value="agent">Agent</MenuItem>
                      {selectedTab === "tab1" && <MenuItem value="status">Status</MenuItem>}
                      {selectedTab === "tab2" && <MenuItem value="lender">Lender</MenuItem>}
                      <MenuItem value="phone">Phone</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    placeholder="Type here to Search"
                    value={inputValue}
                    onChange={handleSearchChange}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon style={{ color: "#031A6E" }} />
                        </InputAdornment>
                      ),
                    }}
                    style={{
                      marginRight: "10px",
                      borderRadius: "4px",
                      borderColor: "#324589",
                    }}
                  />

                  <button
                    onClick={handleSearchClick}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#324589",
                      color: "white",
                    }}
                  >
                    Search
                  </button>
                </>

              )}
            </div>

            {/* //Filters For Date */}
            <div className="date-filters-container">
              <div className="date-input-container">
                <label style={{ color: "#324589", fontSize: "14px" }}>
                  From Date:
                </label>
                <TextField
                  type="date"
                  className="date-input"
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <CalendarTodayIcon style={{ color: "#031A6E" }} />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  variant="outlined"
                  size="small"
                  style={{
                    borderColor: "#324589",
                    marginRight: "8px",
                    borderRadius: "4px",
                  }}
                />
                <label
                  style={{
                    color: "#324589",
                    fontSize: "14px",
                    marginLeft: "8px",
                  }}
                >
                  To Date:
                </label>
                <TextField
                  type="date"
                  className="date-input"
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <CalendarTodayIcon style={{ color: "#031A6E" }} />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  variant="outlined"
                  size="small"
                  style={{
                    borderColor: "#324589",
                    marginRight: "10px",
                    borderRadius: "4px",
                  }}
                />
                <button style={{ backgroundColor: '#324589', borderRadius: '4px' }} onClick={handleSearchDate}>
                  <SearchOutlinedIcon style={{ color: 'white' }} />
                </button>
                <button
                  onClick={downloadCSV}
                  style={{
                    marginLeft: "20px",
                    padding: "10px 20px",
                    backgroundColor: "#324589",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Download CSV
                </button>
              </div>
            </div>
          </div>

          {/* Tabs */}
          <div
            className="tab-container "
            style={{
              border: "1px solid #031A6E",
              padding: "2px",
              width: "450px",
              borderRadius: "5px",
            }}
          >
            <button
              className={`tab-button ${selectedTab === "tab1" ? "active" : ""}`}
              // onClick={() => setSelectedTab('tab1')}
              onClick={() => handleTabChange("tab1")}
              style={{
                padding: "10px 20px",
                //margin: "0 6px",
                backgroundColor: selectedTab === "tab1" ? "#BDC6E7" : "#ffffff",
                color: selectedTab === "tab1" ? "#000000" : "#000000",
                cursor: "pointer",
                fontWeight: "bold",
                transition:
                  "background-color 0.3s, color 0.3s, border-color 0.3s",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Lead Status Dashboard
            </button>
            <button
              className={`tab-button ${selectedTab === "tab2" ? "active" : ""}`}
              // onClick={() => setSelectedTab('tab2')}
              onClick={() => handleTabChange("tab2")}
              style={{
                padding: "10px 20px",
                //margin: '0 10px',
                backgroundColor: selectedTab === "tab2" ? "#BDC6E7" : "#ffffff",
                color: selectedTab === "tab2" ? "#000000" : "#000000",
                cursor: "pointer",
                fontWeight: "bold",
                transition:
                  "background-color 0.3s, color 0.3s, border-color 0.3s",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Disbursement Dashboard
            </button>
          </div>
          <div className="data-page">
            <>
              {loading ? (
                <div className="loader">
                  <Oval
                    height={40}
                    width={40}
                    color="#4A90E2"
                    wrapperStyle={{ margin: '20px auto' }}
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4A90E2"
                  />
                </div>
              ) :

                (<>
                  {/* Tab Content */}
                  {selectedTab === "tab1" && (
                    <div className="table-container">
                      <table className="data-table" style={{ borderColor: "#324589" }}>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Contact No</th>
                            <th>Employment Type</th>
                            <th>Income</th>
                            <th> State</th>
                            <th>Pincode</th>
                            <th>Transaction ID</th>
                            <th>Created On</th>
                            <th>Status</th>
                            <th>Offer Type</th>
                            <th>Agent ID</th>
                            <th>Loan Type</th>
                            <th>Lead Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderData((dataTab1), currentPageTab1).map((item) => (
                            <tr key={item?.id}>
                              <td>{item?.id ?? 'NA'}</td>
                              <td>{item?.first_name ?? 'NA'}</td>
                              <td>{item?.last_name ?? 'NA'}</td>
                              <td>{item?.contact_number ?? 'NA'}</td>
                              <td>{item?.employment_type ?? 'NA'}</td>
                              <td>{item?.income !== null ? item.income : 'NA'}</td>
                              <td>{item?.state ?? 'NA'}</td>
                              <td>{item?.pincode ?? 'NA'}</td>
                              <td>{item?.transaction_id ?? 'NA'}</td>
                              <td>{item?.created_on ?? 'NA'}</td>
                              <td>{item?.status ?? 'NA'}</td>
                              <td>{item?.offer_type ?? 'NA'}</td>
                              <td>{item?.agent_id ?? 'NA'}</td>
                              <td>{item?.loan_type ?? 'NA'}</td>
                              <td>
                                <button
                                  style={{
                                    backgroundColor: "#031A6E",
                                    borderRadius: "5px",
                                    color: "white",
                                  }}
                                  onClick={() => openModal(item)}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                      <div
                        className="pagination me-5"
                        style={{
                          marginTop: "150px",
                          display: "flex",
                          justifyContent: "end",
                          gap: '10px'
                        }}
                      >
                        <select style={{ borderRadius: '18px' }} defaultValue={size == "custom" ? customSize : size.toString()} onChange={handleSizeChange}>
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value="custom">Custom[{customSize}]</option>
                        </select>
                        {size === "custom" && customSize === "" && (
                          <input
                            ref={inputRef} // Attach the ref to the input
                            style={{ borderRadius: '5px', borderStyle: 'bold' }}
                            type="text"
                            // value={customSize}
                            //defaultValue={size}
                            // onChange={handleCustomSizeChange}
                            //onChange={handleCustomSizeChange}
                            onKeyDown={handleCustomSizeChange} // Call function on Enter key
                            placeholder="Enter custom size"
                          />
                        )}

                        <button
                          style={{
                            color: currentPageTab1 === 0 ? "#ccc" : "white", // Change text color when disabled
                            backgroundColor:
                              currentPageTab1 === 0 ? "#8A8A8A" : "#031A6E", // Change background color when disabled
                            marginRight: "10px",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            cursor: currentPageTab1 === 0 ? "not-allowed" : "pointer",
                          }}
                          onClick={() => handlePageChange("tab1", "prev")}
                          disabled={currentPageTab1 === 0}
                        >
                          {currentPageTab1}
                        </button>
                        <button
                          style={{
                            color: "white",
                            backgroundColor: (currentPageTab1 + 1) >= total_pages ? "#8A8A8A" : "#031A6E",
                            marginRight: "10px",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            cursor: (currentPageTab1 + 1) >= total_pages ? "not-allowed" : "pointer",
                          }}
                          onClick={() =>
                            handlePageChange("tab1", "next")
                          } /*disabled={currentPageTab1 == Math.ceil(filteredData(dataTab1).length / itemsPerPage)}*/
                          disabled={(currentPageTab1 + 1) >= total_pages}
                        >
                          {/* {renderPagination(currentPageTab1, total_pages)} */}
                          {currentPageTab1 + 1}
                        </button>
                      </div>
                    </div>

                  )}

                  {selectedTab === "tab2" && (
                    <div className="table-container">
                      <table className="data-table" style={{ borderColor: "#324589" }}>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Contact No</th>
                            <th>Employment Type</th>
                            <th>Income</th>
                            <th> State</th>
                            <th>Pincode</th>
                            <th>Transaction ID</th>
                            <th>Created On</th>
                            <th>Status</th>
                            <th>Offer Type </th>
                            <th>Agent ID</th>
                            <th>Loan Type</th>
                            <th>Settlement Amount</th>
                            <th>Lender Name</th>
                            <th>Disbursement Amount</th>
                            <th>Disbursement Date</th>
                            <th>Lead Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderData((dataTab2), currentPageTab2).map(

                            (item) => (
                              <tr key={item?.id}>
                                <td>{item?.id}</td>
                                <td>{item?.first_name}</td>
                                <td>{item?.last_name}</td>
                                <td>{item?.contact_number}</td>
                                <td>{item?.employment_type}</td>
                                <td>{item?.income}</td>
                                <td>{item?.state}</td>
                                <td>{item?.pincode}</td>
                                <td>{item?.transaction_id}</td>
                                <td>{item?.created_on}</td>
                                <td>{item?.status}</td>
                                <td>{item?.offer_type ?? 'NA'}</td>
                                <td>{item?.agent_id}</td>
                                <td>{item?.loan_type}</td>
                                <td>{item?.settlement_amount}</td>
                                <td>{item?.bpp_name}</td>
                                <td>{item?.disbursement_amount}</td>
                                <td>{item?.disbursement_date}</td>
                                <td>
                                  <button
                                    style={{
                                      backgroundColor: "#031A6E",
                                      borderRadius: "5px",
                                      color: "white",
                                    }}
                                    onClick={() => openModal(item)}
                                  >
                                    {" "}
                                    View
                                  </button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      <div
                        className="pagination me-5"
                        style={{
                          marginTop: "150px",
                          display: "flex",
                          justifyContent: "end",
                          gap: '10px'
                        }}
                      >
                        <select style={{ borderRadius: '18px' }} defaultValue={size == "custom" ? customSize : size.toString()} onChange={handleSizeChange}>
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value="custom">Custom[{customSize}]</option>
                        </select>
                        {size === "custom" && customSize === "" && (
                          <input
                            ref={inputRef} // Attach the ref to the input
                            style={{ borderRadius: '5px', borderStyle: 'bold' }}
                            type="text"
                            // value={customSize}
                            //defaultValue={size}
                            // onChange={handleCustomSizeChange}
                            //onChange={handleCustomSizeChange}
                            onKeyDown={handleCustomSizeChange} // Call function on Enter key
                            placeholder="Enter custom size"
                          />
                        )}
                        <button
                          style={{
                            color: "white",
                            backgroundColor:
                              currentPageTab2 === 0 ? "#8A8A8A" : "#031A6E",
                            marginRight: "10px",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            cursor: currentPageTab2 === 0 ? "not-allowed" : "pointer",
                          }}
                          onClick={() => handlePageChange("tab2", "prev")}
                          disabled={currentPageTab2 === 0}
                        >
                          {currentPageTab2}
                        </button>
                        <button
                          style={{
                            color: "white",
                            backgroundColor:
                              (currentPageTab2 + 1) >= total_pages ? "#8A8A8A" : "#031A6E", // Change background color when disabled
                            marginRight: "10px",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            cursor: (currentPageTab2 + 1) >= total_pages ? "not-allowed" : "pointer",
                          }}
                          onClick={() =>
                            handlePageChange("tab2", "next")
                          } /*disabled={currentPageTab2 === Math.ceil(filteredData(dataTab2).length / itemsPerPage)}*/
                          disabled={(currentPageTab2 + 1) > total_pages}
                        >
                          {currentPageTab2 + 1}
                        </button>
                      </div>
                    </div>
                  )}
                </>)}
            </>
          </div>

          {/* Modal */}
          {isModalOpen && selectedItem && (
            <div className="modal" style={{ borderColor: "#324589" }}>

              <div className="modal-content" style={{ borderColor: "#324589" }}>
                <div style={{ display: 'flex', gap: '570px' }}> <h2>Details</h2>   <CancelIcon onClick={closeModal} style={{ cursor: 'pointer', color: "black" }} /> </div>
                <p style={{ fontWeight: "bold" }}>ID : {selectedItem.id}</p>
                <p style={{ fontWeight: "bold" }}>
                  First Name: {selectedItem.first_name}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Last Name: {selectedItem.last_name}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Contact Number: {selectedItem.contact_number}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Employment Type: {selectedItem.employment_type}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Income: {selectedItem.income}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  State: {selectedItem.state}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Pincode: {selectedItem.pincode}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Transaction Id: {selectedItem.transaction_id}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Created On: {selectedItem.created_on}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Status: {selectedItem.status}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Offer Type: {selectedItem.offer_type}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Agent Id: {selectedItem.agent_id}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Loan Type: {selectedItem.loan_type}
                </p>
                {selectedTab === "tab2" && (<>
                  <p style={{ fontWeight: "bold" }}>
                    Settlement Amount: {selectedItem.settlement_amount}
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Lender Name: {selectedItem.bpp_name}
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Disbursement Amount: {selectedItem.disbursement_amount}
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Disbursement Date: {selectedItem.disbursement_date}
                  </p>
                </>
                )}

                <button
                  onClick={closeModal}
                  style={{
                    //borderColor: "#324589",
                    color: 'white',
                    borderRadius: '5px',
                    backgroundColor: '#324589',
                    width: "70px",
                    marginBottom: "50px",
                    marginLeft: '600px'
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>{" "}
      </>
    </div>
  );
};

export default SuccessPage;