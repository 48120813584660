import React from "react"; 
export const URL=process.env.REACT_APP_API_BASE_URL;
export const BACKEND = process.env.REACT_APP_BACKEND_ENV;
const nbt_user =sessionStorage.getItem("nbt_user");
const URLS = {

    // nbt users routes
    
    get_lead:`/admin/${nbt_user}/dashboard?dashboardType=lead&page=`,
    get_disbursed:`/admin/${nbt_user}/dashboard?dashboardType=disbursed&page=`,
    get_lead_admin:`/admin/${nbt_user}/application-dashboard?dashboardType=lead&page=`,
    get_disbursed_admin:`/admin/${nbt_user}/application-dashboard?dashboardType=disbursed&page=`,
    get_client_admin:`/openfinance-preprod/client`,
    get_fetch_clients: `/admin/protean_admin/client` , 
  };
  
  export default URLS;
  export{};